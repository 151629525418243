import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    snakeBody: {
        height: '50px',
        width: '50px',
        color: 'red',
        position: 'absolute',
        start: '50px',
        background: 'red'
    }
}));

const Snake = () => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.snakeBody}>
            </div>
        </div>
    )
};

export default Snake;
