import React, { useRef } from 'react';

import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';

import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ContactMailIcon from '@material-ui/icons/ContactMail';

import useTypedJS from '../../effects/typed/useTyped';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  listIcons: {
    display: 'flex',
    width: 'min-content'
  },
  grid: {
    flexGrow: 1
  },
  
  gridRight: {
    marginTop: '15%',
  },
  gridLeft: { 
    marginTop: '15%',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  liCenter: {
    display: 'block',
    textAlign: 'center'
  },
  btn: {
    background: 'black',
    color: 'white',
    fontSize: 'smaller',  
    fontWeight: 900
  },
  badge: {
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }
}));

const Home = () => {
  const typedRef = useRef();
  useTypedJS(
      typedRef, 
      {
        strings: [
          `<div 
            class="MuiListItemText-root MuiListItemText-multiline"
          >
            <h4
              class="MuiTypography-root MuiTypography-h4 MuiTypography-gutterBottom" 
              style="font-family: 'Raleway', sans-serif;"
            >
              Hi!, I'm a software developer with some full-stack skills,
              I can solve all your problems,
            </h4>
          </div>`,
          `<div 
            class="MuiListItemText-root MuiListItemText-multiline"
          >
            <h4
              class="MuiTypography-root MuiTypography-h4 MuiTypography-gutterBottom" 
              style="font-family: 'Raleway', sans-serif;"
            >
              Hi!, I'm a software developer with some full-stack skills,
              I can solve <s style="color: gray">all of your</s> problems.
            </h4>
            <h5
              class="MuiTypography-root MuiTypography-h5 MuiTypography-gutterBottom" 
              style="font-family: 'Raleway', sans-serif;"
            >
              I design and build applications.
              If you have a project, idea or problem, would you'd like to discuss?
            </h5>
            <p 
              class="MuiTypography-root MuiListItemText-secondary MuiTypography-h6 MuiTypography-colorTextSecondary MuiTypography-displayBlock"
            >
              Let's chat gerardoghuizar@gmail.com
            </p>
          </div>`
        ],
        typeSpeed: 10,
        backSpeed: 10
      });
  const classes = useStyles();

  return (
    <div>
      <Grid className={classes.grid} container spacing={2}>
        <Grid className={classes.gridLeft} item md={8} xs={12}>
          <div ref={typedRef} ></div>
        </Grid>
        <Grid className={classes.gridRight} item md={4} xs={12}>
          <List
            className={classes.root}
          >
            <ListItem className={classes.liCenter}>
              <Fab
                variant="extended"
                size="small"
                aria-label="add"
                className={classes.btn}
                href="https://medium.com/@gerardoghuizar"
              >
                Blog
              </Fab>
            </ListItem>
            <ListItem className={classes.liCenter}>
              <Fab 
                variant="extended"
                size="small"
                aria-label="add"
                className={classes.btn}
                href="/contact" 
              >
                Contact
              </Fab>
            </ListItem>
            <ListItem className={classes.liCenter}>
              <Fab 
                variant="extended"
                size="small"
                aria-label="add"
                className={classes.btn}
                href="/briefcase"
              >
                Briefcase
              </Fab>
            </ListItem>
          </List>
          <center>
            <List className={classes.listIcons}>
              <ListItem>
                <Fab 
                  size="small"  
                  aria-label="add" 
                  className={classes.margin}
                  href="https://twitter.com/GerardoHuizar5"
                >
                  <TwitterIcon />
                </Fab>
              </ListItem>
              <ListItem>
                <Fab
                  size="small"  
                  aria-label="add" 
                  className={classes.margin}
                  href="https://www.linkedin.com/in/gerardo-gutierrez-huizar/"
                >
                  <LinkedInIcon />
                </Fab>
              </ListItem>
              <ListItem>
                <Fab
                  size="small"  
                  aria-label="add" 
                  className={classes.margin}
                  href="https://github.com/GerardoHuizar"
                >
                  <GitHubIcon />
                </Fab>
              </ListItem>
              <ListItem>
                <Fab
                  size="small"  
                  aria-label="add" 
                  className={classes.margin}
                  href="mailto:gerardoghuizar@gmail.com"
                >
                  <ContactMailIcon />
                </Fab>
              </ListItem>
            </List>
          </center>
        </Grid>
      </Grid>
    </div>
  )
}

export default Home;
