import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import img from '../../assets/perfil.jpeg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  rootImg: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  shape: {
    width: 40,
    height: 40,
  },
}));

const Navbar = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Toolbar>
        <IconButton 
          edge="start"
          className={classes.menuButton} 
          color="inherit"
          aria-label="menu"
        >
          <div className={classes.rootImg}>
            <Avatar
              alt="Gerardo Huizar"
              src={img}
              className={classes.large}
            />
          </div>
        </IconButton>
        <ListItemText
          primary={
            <Typography variant='h6' gutterBottom>
              Eng. Gerardo G'Huizar
            </Typography>
          }
          secondary="Software Developer"
        />
      </Toolbar>
    </div>
  )
}

export default Navbar;
