import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '70px'
  },
  bottomContainer: {
    bottom: 0,
    position: 'fixed',
    width: '70%'
  }
}));

const Footer = () => {
  const classes = useStyles();
  const match = useMediaQuery('(min-width:600px)');

  return ( 
    match && (
      <div className={classes.container}>
        <Grid className={classes.bottomContainer} item md={4}>
          <Typography variant="body2" gutterBottom>
            Created with ReactJs & ParcelJs by GerardoHuizar 👾
          </Typography>
        </Grid>
      </div>
    )
  );
};

export default Footer;
