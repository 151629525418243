import React from 'react';
import { render } from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Navbar from './components/navbar/Component';
import Footer from './components/footer/Component';
import Snake from './components/snake/Component';
import Contact from './views/contact/Component';
import Home from './views/home/Component';

const App = () => {
  return (
    <Router>
      <CssBaseline />
      <Snake />
      <Container style={{ marginTop: '1%' }}>
        <Navbar />

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/briefcase">
            <Contact />
          </Route>
        </Switch>
      <Footer />
      </Container>
    </Router>
  );
};

render(<App />, document.getElementById("app"));
